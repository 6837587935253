import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import pharmaAndRearDisease from "../../../assets/images/blog/rear_disease/rear_disease-min.png"

export default () => 
<div>
    <SEO title={'Pharma Design and Digital Marketing Case Studies | ICON Worldwide'} 
    description="Looking for some successful design and digital marketing case studies related to the pharma industry? If so, just give a look at our blog!"
    canonical={'https://icon-worldwide.com/blog/pharma-digital-marketing-case-studies'} />

    <NewsHeader/>

    <div id="single-news">
        <h1>Pharma and Rare Disease <span>Design and Digital Marketing</span> Case Studies</h1>
        <div id="title-underline"></div>
        <div id="single-news-texts"></div>


        <div id="pharma-rear-disease">
            <img src={pharmaAndRearDisease} alt="pharma digital marketing case studies, ICON Worldwide Blog, rare disease" title=" ICON Worldwide blog, pharma digital marketing case studies, rare disease"/>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.designrush.com/agency/healthcare-software-development-companies">Top Healthcare Software Development Companies Of 2020 according to DesignRush</a></p>
        </div>
        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

        <NewsFooter previous="top-20-for-healthcare-software" next="how-to-improve-digital-marketing-roi"/>
    </div>

    <Footer noScrollbar="true"/>
</div>